import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import Utils from '_app/utils';

export const GoogleAutoComplete = ({ onChangeLocation = () => {} }) => {
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);
  const { ref, clickedOutside, setClickedOutside } = Utils.useOutSideClick(false);

  useEffect(() => {
    setClickedOutside(list.length === 0);
  }, [list, setClickedOutside]);

  const onSelectPlaceList = useCallback(
    async (placeId) => {
      setList([]);
      setValue('');
      const data = await Utils.googlePlaceDetails(placeId, ref.current);
      if (data && data.geometry && data.geometry.location) {
        const zoom = Utils.getZoomFromViewPort(data.geometry.viewport);
        onChangeLocation({ lat: data.geometry.location.lat(), lng: data.geometry.location.lng() }, zoom);
      }
    },
    [onChangeLocation, ref],
  );

  const getPlacesByText = useMemo(
    () =>
      debounce(async (input) => {
        if (!input) {
          setList([]);
          return;
        }
        const results = await Utils.googleAutocomplete(input);
        if (results) {
          setList(results);
        }
      }, 500),
    [],
  );

  return (
    <div className="bg-primary2 p-2 relative z-50">
      <input
        type="text"
        placeholder="Type city, zip code or location..."
        className="bg-white rounded px-2 w-full h-8 outline-none font-primary"
        value={value || ''}
        onChange={({ target }) => {
          getPlacesByText(target.value);
          setValue(target.value);
        }}
      />
      <div
        ref={ref}
        className={`absolute top-14 left-2 py-1 border bg-white shadow-2xl rounded overflow-hidden min-w-max ${clickedOutside ? 'hidden' : ''}`}>
        {list.map((address, index) => (
          <div
            className="py-2 px-4 pr-6 cursor-pointer transition duration-500 ease-in-out bg-white hover:bg-primary3 hover:text-white"
            onClick={() => onSelectPlaceList(address.place_id)}
            key={index}>
            {address.description}
          </div>
        ))}
      </div>
    </div>
  );
};
