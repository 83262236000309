import React from 'react';

import { ReactComponent as ArrowIcon } from '_app/assets/images/arrow_down.svg';

export const Select = ({ items = [], containerClassName = '', className = '', value = undefined, onChange = () => {} }) => {
  return (
    <div className={'flex-1 relative ' + containerClassName}>
      <select
        className={`appearance-none w-full border p-2 border-gray-400 rounded ${className}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}>
        {items.map((item) => (
          <option key={item.value} value={item.value} label={item.label} />
        ))}
      </select>
      <div className="absolute right-1 top-0 bottom-0 flex justify-center items-center">
        <ArrowIcon width={24} heigt={24} fill="blue" />
      </div>
    </div>
  );
};
