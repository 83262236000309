import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { LoadingView } from '../LoadingView';
import { BaseModal } from './BaseModal';
import { Button, FlexCol, FlexRow, HR, Label } from '..';
import { fetchUpcomingsAction } from '_app/store/activity/action';
import { globalUpcomingSelector } from '_app/store/activity/selector';
import { PropertyInfoModal } from '.';
import { fetchPropertyInfoAction } from '_app/store/property/action';

export const OpenHousesModal = ({ visible, mlsnum = null, onClose = () => { } }) => {
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const { items, loading } = useSelector(globalUpcomingSelector);

  useEffect(() => {
    dispatch(fetchUpcomingsAction(mlsnum));
  }, [dispatch, mlsnum, visible]);

  const onOpenPreview = ({ mlsnum }) => {
    dispatch(fetchPropertyInfoAction(mlsnum));
    setOpenPreview(true);
  };

  return (
    <BaseModal
      visible={visible}
      onClose={onClose}
      overlayClassName="items-center"
      className="my-auto w-screen max-w-screen-sm px-2"
    >
      <FlexCol className="bg-white max-h-96 w-full pb-2 rounded">
        <FlexRow className="p-4 items-center justify-between flex-shrink-0">
          <FlexRow className="items-center">
            <FontAwesomeIcon
              icon={['fas', 'home']}
              className="text-base text-primary5 mr-2 mb-0.5"
            />
            <Label
              label={`OPEN HOUSES${items.length ? ` (${items.length})` : ''}`}
              className="text-primary5 text-base font-primary-bold"
            />
          </FlexRow>
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="text-lg cursor-pointer text-primary5 mr-1"
            onClick={onClose}
          />
        </FlexRow>
        <HR className="flex-shrink-0" />
        <FlexCol className="overflow-y-auto px-4 pb-20">
          {items.map((item, index) => (
            <div key={index} className="text-black pt-2">
              <FlexCol>
                <Button
                  label={item.address}
                  className="text-primary5 text-lg font-primary-bold"
                  onClick={() => onOpenPreview(item)}
                />
                <FlexCol>
                  <Label
                    className="text-primary2 font-primary-bold text-base leading-3"
                    // Display date in America/Chicago
                    label={moment(item.datetime_start)
                      .tz('America/Chicago')
                      .format('dddd, MMM DD')}
                  />
                  <Label
                    className="text-primary2 font-primary-bold"
                    // Display time range in America/Chicago
                    label={`${moment(item.datetime_start)
                      .tz('America/Chicago')
                      .format('hh:mm A')} - ${moment(item.datetime_end)
                        .tz('America/Chicago')
                        .format('hh:mm A')}`}
                  />
                </FlexCol>
                {!!item.description && (
                  <Label
                    className="text-primary2 italic font-primary-bold"
                    label={`"${item.description}"`}
                  />
                )}
              </FlexCol>
            </div>
          ))}
        </FlexCol>
      </FlexCol>
      <PropertyInfoModal visible={openPreview} onClose={() => setOpenPreview(false)} />
      {items.length === 0 && loading && <LoadingView />}
    </BaseModal>
  );
};