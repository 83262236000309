export const mapSearchFormBySection = (data = []) => {
  if (!data.length) {
    return [];
  }

  const sections = [];

  const searchSection = [
    data.find((item) => item.name === 'search_sale_type'),
    data.find((item) => item.name === 'search_prop_type'),
    data.find((item) => item.name === 'search_mlsnum'),
    data.find((item) => item.name === 'search_mlsnum_condition'),
  ];
  sections.push({ title: 'Search', simple: true, data: searchSection });

  const condoSection = [
    {
      label: 'Building Name',
      items: [data.find((item) => item.name === 'search_building_name'), data.find((item) => item.name === 'search_building_name_condition')],
    },
    {
      label: 'Building Access',
      items: [data.find((item) => item.name === 'search_building_access'), data.find((item) => item.name === 'search_building_access_condition')],
    },
    {
      label: 'View',
      items: [data.find((item) => item.name === 'search_view_desc'), data.find((item) => item.name === 'search_view_desc_condition')],
    },
  ];
  sections.push({ title: 'Condo Details', simple: true, data: condoSection });

  const propertyInfoSection = [
    {
      label: 'List Price ($)',
      items: [data.find((item) => item.name === 'search_list_price_min'), data.find((item) => item.name === 'search_list_price_max')],
    },
    {
      label: 'Beds',
      items: [
        data.find((item) => item.name === 'search_beds_min'),
        data.find((item) => item.name === 'search_beds_max'),
        data.find((item) => item.name === 'search_beds_exclude_null'),
      ],
    },
    {
      label: 'Full Bath',
      items: [
        data.find((item) => item.name === 'search_baths_full_min'),
        data.find((item) => item.name === 'search_baths_full_max'),
        data.find((item) => item.name === 'search_baths_full_exclude_null'),
      ],
    },
    {
      label: 'Sq Foot',
      items: [
        data.find((item) => item.name === 'search_sqft_bldg_min'),
        data.find((item) => item.name === 'search_sqft_bldg_max'),
        data.find((item) => item.name === 'search_sqft_bldg_exclude_null'),
      ],
    },
    {
      label: 'Lot Size',
      items: [
        data.find((item) => item.name === 'search_lot_size_combined_min'),
        data.find((item) => item.name === 'search_lot_size_combined_max'),
        data.find((item) => item.name === 'search_lot_size_combined_exclude_null'),
      ],
    },
    {
      label: 'Maint $(mo)',
      items: [data.find((item) => item.name === 'search_monthly_maint_fee_min'), data.find((item) => item.name === 'search_monthly_maint_fee_max')],
    },
    {
      label: 'Year Built',
      items: [
        data.find((item) => item.name === 'search_year_built_min'),
        data.find((item) => item.name === 'search_year_built_max'),
        data.find((item) => item.name === 'search_year_built_exclude_null'),
      ],
    },
    {
      label: '# Garage',
      items: [
        data.find((item) => item.name === 'search_garage_cap_min'),
        data.find((item) => item.name === 'search_garage_cap_max'),
        data.find((item) => item.name === 'search_garage_cap_exclude_null'),
      ],
    },
    {
      label: 'Stories',
      items: [
        data.find((item) => item.name === 'search_stories_min'),
        data.find((item) => item.name === 'search_stories_max'),
        data.find((item) => item.name === 'search_stories_exclude_null'),
      ],
    },
    {
      label: 'Floor #',
      items: [
        data.find((item) => item.name === 'search_floor_location_search_min'),
        data.find((item) => item.name === 'search_floor_location_search_max'),
        data.find((item) => item.name === 'search_floor_location_search_exclude_null'),
      ],
      advanced: true,
    },
    {
      label: 'DOM',
      items: [data.find((item) => item.name === 'search_days_on_market_min'), data.find((item) => item.name === 'search_days_on_market_max')],
      advanced: true,
    },
    {
      label: 'Pool',
      items: [data.find((item) => item.name === 'search_pool_area')],
    },
    {
      label: 'Private Pool',
      items: [data.find((item) => item.name === 'search_pool_private')],
      advanced: true,
    },
    {
      label: 'Lot Value only',
      items: [data.find((item) => item.name === 'search_lot_value'), data.find((item) => item.name === 'search_lot_value_exclude_null')],
    },
    {
      label: 'New Construction',
      items: [
        data.find((item) => item.name === 'search_new_construction'),
        data.find((item) => item.name === 'search_new_construction_exclude_null'),
      ],
      advanced: true,
    },
  ];
  sections.push({ title: 'Property Info', simple: true, data: propertyInfoSection });

  const locationSection = [
    {
      label: 'Area',
      labelLink: 'http://search.har.com/engine/dispmapsearch.cfm',
      items: [data.find((item) => item.name === 'search_area'), data.find((item) => item.name === 'search_area_condition')],
    },
    {
      label: 'Zip',
      items: [data.find((item) => item.name === 'search_zip_code'), data.find((item) => item.name === 'search_zip_code_condition')],
    },
    {
      label: 'City',
      items: [data.find((item) => item.name === 'search_city'), data.find((item) => item.name === 'search_city_condition')],
      advanced: true,
    },
    {
      label: 'Geo Market Area',
      labelLink: 'http://web.har.com/mlsareamap.html',
      items: [data.find((item) => item.name === 'search_geo_market_area'), data.find((item) => item.name === 'search_geo_market_area_condition')],
    },
    {
      label: 'Subdivision',
      items: [data.find((item) => item.name === 'search_subdivision'), data.find((item) => item.name === 'search_subdivision_condition')],
      advanced: true,
    },
    {
      label: 'Legal Subdivision/Desc',
      items: [data.find((item) => item.name === 'search_legal_subdivision'), data.find((item) => item.name === 'search_legal_subdivision_condition')],
      advanced: true,
    },
    {
      label: 'Street #',
      items: [data.find((item) => item.name === 'search_street_num_min'), data.find((item) => item.name === 'search_street_num_max')],
      advanced: true,
    },
    {
      label: 'Street Name',
      items: [data.find((item) => item.name === 'search_street_name'), data.find((item) => item.name === 'search_street_name_condition')],
      advanced: true,
    },
    {
      label: 'Key Map',
      items: [data.find((item) => item.name === 'search_map_page'), data.find((item) => item.name === 'search_map_page_condition')],
      advanced: true,
    },
  ];

  sections.push({ title: 'Location', simple: true, data: locationSection });

  const additionalOptionSection = [
    {
      label: 'Interior Features',
      items: [data.find((item) => item.name === 'search_interior'), data.find((item) => item.name === 'search_interior_condition')],
    },
    {
      label: 'Room Description',
      items: [data.find((item) => item.name === 'search_room_desc'), data.find((item) => item.name === 'search_room_desc_condition')],
    },
    {
      label: 'Bedroom Description',
      items: [data.find((item) => item.name === 'search_room_bed_desc'), data.find((item) => item.name === 'search_room_bed_desc_condition')],
    },
    {
      label: 'Exterior',
      items: [data.find((item) => item.name === 'search_exterior'), data.find((item) => item.name === 'search_exterior_condition')],
    },
    {
      label: 'Exterior Construction',
      items: [data.find((item) => item.name === 'search_siding'), data.find((item) => item.name === 'search_siding_condition')],
    },
    {
      label: 'Style Desc',
      items: [data.find((item) => item.name === 'search_style'), data.find((item) => item.name === 'search_style_condition')],
    },
    {
      label: 'Access',
      items: [data.find((item) => item.name === 'search_access'), data.find((item) => item.name === 'search_access_condition')],
    },
    {
      label: 'Front Door Faces',
      items: [data.find((item) => item.name === 'search_front_door_faces'), data.find((item) => item.name === 'search_front_door_faces_condition')],
    },
    {
      label: 'Garage Desc',
      items: [data.find((item) => item.name === 'search_garage_desc'), data.find((item) => item.name === 'search_garage_desc_condition')],
    },
    {
      label: 'Waterfront Features',
      items: [data.find((item) => item.name === 'search_water_amenity'), data.find((item) => item.name === 'search_water_amenity_condition')],
    },
    {
      label: 'Acreage',
      items: [data.find((item) => item.name === 'search_acres_desc'), data.find((item) => item.name === 'search_acres_desc_condition')],
    },
    {
      label: 'Lot Desc',
      items: [data.find((item) => item.name === 'search_lot_desc'), data.find((item) => item.name === 'search_lot_desc_condition')],
    },
    {
      label: 'Restrictions',
      items: [data.find((item) => item.name === 'search_restrictions'), data.find((item) => item.name === 'search_restrictions_condition')],
    },
    {
      label: 'Disclosures',
      items: [data.find((item) => item.name === 'search_disclosures'), data.find((item) => item.name === 'search_disclosures_condition')],
    },
    {
      label: 'HOA Amenities',
      items: [data.find((item) => item.name === 'search_hoa_amenities'), data.find((item) => item.name === 'search_hoa_amenities_condition')],
    },
    {
      label: 'Master Planned Community Name',
      items: [
        data.find((item) => item.name === 'search_master_planned_community'),
        data.find((item) => item.name === 'search_master_planned_community_condition'),
      ],
    },
    {
      label: 'Total Tax Rate',
      type: 'rangeInput',
      items: [
        data.find((item) => item.name === 'search_tax_rate_min'),
        data.find((item) => item.name === 'search_tax_rate_max'),
        data.find((item) => item.name === 'search_tax_rate_exclude_null'),
      ],
    },
    {
      label: 'Master Planned Community?',
      items: [data.find((item) => item.name === 'search_master_planned_community_yn')],
    },
    {
      label: 'Text Description',
      type: 'input',
      items: [data.find((item) => item.name === 'search_remarks'), data.find((item) => item.name === 'search_remarks_condition')],
    },
    {
      label: 'Taxes w/o Exemption/Yr',
      type: 'rangeInput',
      items: [
        data.find((item) => item.name === 'search_tax_amount_min'),
        data.find((item) => item.name === 'search_tax_amount_max'),
        data.find((item) => item.name === 'search_tax_amount_exclude_null'),
      ],
    },
    {
      label: 'Property Subtype',
      items: [data.find((item) => item.name === 'search_prop_subtype'), data.find((item) => item.name === 'search_prop_subtype_condition')],
    }
  ];

  sections.push({ title: 'Additional Options', simple: false, data: additionalOptionSection });

  const schoolSection = [
    {
      label: 'Elemantary School',
      items: [
        data.find((item) => item.name === 'search_geo_school_elementary'),
        data.find((item) => item.name === 'search_geo_school_elementary_condition'),
      ],
    },
    {
      label: 'Middle Schools',
      items: [data.find((item) => item.name === 'search_geo_school_middle'), data.find((item) => item.name === 'search_geo_school_middle_condition')],
    },
    {
      label: 'High School',
      items: [data.find((item) => item.name === 'search_geo_school_high'), data.find((item) => item.name === 'search_geo_school_high_condition')],
    },
  ];

  sections.push({ title: 'School Information', simple: true, data: schoolSection });

  const rentalSection = [
    {
      label: 'Utilites',
      items: [data.find((item) => item.name === 'search_utilities'), data.find((item) => item.name === 'search_utilities_condition')],
    },
    {
      label: 'Appliances',
      items: [data.find((item) => item.name === 'search_appliances'), data.find((item) => item.name === 'search_appliances_condition')],
    },
    {
      label: 'Pets',
      items: [data.find((item) => item.name === 'search_pets'), data.find((item) => item.name === 'search_pets_condition')],
    },
  ];
  sections.push({ title: 'Rentals', simple: true, data: rentalSection });

  return sections;
};
